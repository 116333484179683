import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/account": [~3],
		"/admin": [~4],
		"/auth/callback/confirm": [5],
		"/auth/callback/recovery": [6],
		"/drafts": [~7],
		"/drafts/[slug]": [~8],
		"/how-it-works": [9],
		"/saved-grants": [~10],
		"/scoutcapture": [~11],
		"/scoutwriter": [~12],
		"/scoutwriter/compliance-matrix": [13],
		"/search": [~14],
		"/search/result/compliance-matrix/[grant_id]": [~16],
		"/search/result/[slug]": [~15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';