import { PUBLIC_ENVIRONMENT } from "$env/static/public";

import * as Sentry from "@sentry/sveltekit";
import { feedbackIntegration, handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";

Sentry.init({
    dsn: "https://490ac171d2ac570ed61ef1318d9148b5@o4507071088951296.ingest.us.sentry.io/4507071094063104",
    tunnel: "/api/tunnel",
    enabled: PUBLIC_ENVIRONMENT === "production",
    environment: PUBLIC_ENVIRONMENT,

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        replayIntegration(),
        feedbackIntegration({
            autoInject: false,
            colorScheme: "light",
            showBranding: false,
            useSentryUser: { email: "email", name: "name" },
            themeLight: { submitBackground: "#343330", submitBackgroundHover: "#71706e" },
        }),
    ],

    ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        //
        "punycode",
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
